import { ImagePreview } from '@repo/ui/ImagePreview';
import { Logger } from '@repo/logger';

export default function ImagePreviewPage() {
  const logger = new Logger('ImagePreviewPage');

  return (
    <div class="min-h-screen w-full flex items-center justify-center bg-gray-100">
      {/* Constrain wrapper with fixed dimensions */}
      <div class="w-[800px] h-[600px] overflow-hidden rounded-lg shadow-lg">
        <ImagePreview
          image="/assets/images/sample_band_design.png"
          width="100%" // Fill container width
          height="100%" // Fill container height
          background="#f5f5f5"
          onError={(error) => logger.error('Failed to load image:', error)}
          onLoad={() => logger.info('Image loaded successfully')}
          controls={{
            show: true,
            position: 'bottom',
            backgroundColor: 'rgba(0, 0, 0, 0.7)',
            color: '#ffffff',
          }}
        />
      </div>
    </div>
  );
}
